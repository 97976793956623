export default function NotificationsServiceProvider() {
  this.$get = (vuiNotificationsWsService, Notification) => {
    'ngInject';

    function NotificationsService() {

      const NOTIFICATION_PAGE_SIZE = 100;

      const self = this;
      const handlers = [];
      let inetConnectionStatus = false;
      const notifications = {
        list: [],
        countUnread: 0
      };
      const notificationList = {};

      self.connect = vuiNotificationsWsService.connect;
      self.close = vuiNotificationsWsService.close;

      self.markNewNotificationAsLastViewed = () => {
        vuiNotificationsWsService.send({
          operation: vuiNotificationsWsService.MARK_ALL_READ_CODE
        });
      }

      self.readRequestNotifications = (requestId, type) => {
        vuiNotificationsWsService.send({
          operation: vuiNotificationsWsService.READ_NOTIFICATIONS_CODE,
          requestId: requestId,
          type: type
        });
        notifications.list
          .filter(n => n.metadata && n.metadata.requestId === requestId)
          .forEach(n => n.read = true);
      }

      self.readAllNotifications = () => {
        vuiNotificationsWsService.send({
          operation: vuiNotificationsWsService.READ_ALL_NOTIFICATIONS_CODE,
        });
        notifications.list.forEach(n => n.read = true);
      }

      self.registerHandler = (id, fn, type, alreadyCall) => {
        handlers.push({id: id, type: type || 'ALL', call: fn});
        if (alreadyCall) {
          fn(self.getNotifications())
        }
      }

      self.unregisterHandler = (id) => {
        for (let i = 0; i < handlers.length; i++) {
          if (handlers[i].id === id) {
            handlers.splice(i, 1);
          }
        }
      };

      self.getNotifications = () => {
        return {
          count: notifications.countUnread,
          list: structuredClone(notifications.list) // immutable list
        };
      }

      const changeInetConnectionStatus = function (connected) {
        inetConnectionStatus = connected;
      };

      const receiveAllNotifications = message => {
        addNotificationsToList(message);
        notifications.countUnread = message.data.unreadNotificationResponse.countUnreadNotifications;
        if (message.data.next) {
          loadMoreNotifications();
        } else {
          callHandlers(true);
        }
      };

      const loadMoreNotifications = () => {
        const request = {
          operation: vuiNotificationsWsService.LOAD_MORE_CODE,
          page: 0,
          size: NOTIFICATION_PAGE_SIZE
        };

        if (notifications.list && notifications.list.length > 0) {
          request.lastNotificationId = notifications.list[notifications.list.length - 1].id;
        }

        vuiNotificationsWsService.send(request);
      };

      const addNotificationsToList = message => {
        const list = message.data.data;
        for (let i = 0; i < list.length; i++) {
          if (!notificationList[list[i].id]) {
            notificationList[list[i].id] = true;
            notifications.list.push(list[i]);
          }
        }
      }

      const receiveNotification = message => {
        notifications.list.unshift(message.data);
        notifications.countUnread++;
        Notification.notification(message.data);
        callHandlerForMessage(message.data)
        callHandlers(true);
      };

      const updateCount = message => {
        notifications.countUnread = message.data;
        if (notifications.countUnread === 0 && notifications.list.length > 0) {
          notifications.list.sort((r1, r2) => r2.createdAt - r1.createdAt);
          notifications.list[0].lastViewed = true;
        }
        callHandlers(message.data !== 0);
      }

      const readNotification = message => {
        notifications.countUnread = message.data.countNotRead;
        const ids = message.data.ids;
        if (!ids) {
          return;
        }

        const idx = indexOf(notifications.list, ids[0]);
        if (idx !== -1) {
          notifications.list[idx].read = true;
        }

        callHandlers(false);
      };

      const readAllNotifications = message => {
        notifications.countUnread = message.data.countNotRead;

        const ids = message.data.ids;
        if (!ids) {
          return;
        }

        for (let i = 0; i < ids.length; i++) {
          const index = indexOf(notifications.list, ids[i]);
          if (index !== -1) {
            notifications.list[index].read = true;
          }
        }

        callHandlers(false);
      };

      const onRegister = message => {
        if (notifications.list.length === 0) {
          loadMoreNotifications();
        }
      }

      const indexOf = (arr, id) => {
        let idx = 0;
        for (; arr[idx] && arr[idx].id !== id; idx++) {
        }
        return idx === arr.length ? -1 : idx;
      }

      const callHandlers = needRead => {
        const payload = self.getNotifications();
        handlers.filter(handler => handler.type === 'ALL').forEach(handler => handler.call(payload, needRead));
      }

      const callHandlerForMessage = message => {
        handlers.filter(handler => handler.type === 'NEW').forEach(handler => handler.call(message));
      }

      vuiNotificationsWsService.registerHandler('LIST', 'general', receiveAllNotifications);
      vuiNotificationsWsService.registerHandler('NEW', 'general', receiveNotification);
      vuiNotificationsWsService.registerHandler('COUNT', 'general', updateCount);
      vuiNotificationsWsService.registerHandler('READ_SINGLE', 'general', readNotification);
      vuiNotificationsWsService.registerHandler('READ_LIST', 'general', readAllNotifications);
      vuiNotificationsWsService.registerHandler('REGISTRATION', 'general', onRegister);
      vuiNotificationsWsService.registerInternetConnectionHandler(changeInetConnectionStatus);
    }

    return new NotificationsService();
  };
}