import './cancelation-info.modal.scss';

export default function CancelationInfoModal ($scope, close, penalties) {
  'ngInject';

  $scope.penalties = penalties;

  $scope.ok = () => {
    close(true);
  }

  $scope.close = () => {
    close(false);
  }
}