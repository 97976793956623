import './confirmation.modal.scss';

export default function ConfirmationModal($scope, close, config) {
  'ngInject';

  $scope.config = config;

  $scope.ok = () => {
    close(true);
  }

  $scope.close = () => {
    close(false);
  }
}